<template>
    <div class="default-page">
      <div class="buttons-container">
        <div v-if="active_page === 'news-rss-feeds'" class="add-rss-button">
          <b-button @click="openModal">Upload Sources</b-button>
        </div>
        <div v-if="alias.length" class="create-button">
          <b-button :disabled="!canCreate" @click="create">
            Create a {{alias}}
          </b-button>
        </div>
      </div>
      <div v-if="close" class="close-modal-button">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" @click="open" v-bind:class="'close-button'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

/** This component contains the code required to create new categories, products, topics, sources etc */
export default {
  name: 'AdminHeader',
  props: {
    /** This prop contains the text for the local header (Text on the same line as the Create button). It's currently being used in the *'edit'* modals that open on the admin panel  */
    pageName: String,
    /** This prop contains the text that indicates what type of item needs to be created */
    alias: String,
    /** This prop indicates the behavior to be followed when a new instance of type *alias* is created */
    onCreate: Function,
    /** This prop indicates whether the modal is visible or not */
    close: Boolean,
    /** This prop toggles the visibility of the modal */
    onClose: Function,
    /** This prop indicates whether or not an item is creatable */
    canCreate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      current: ''
    }
  },
  computed: {
    ...mapState('common', ['active_page'])
  },
  methods: {
    create () {
      this.$emit('onCreate')
    },
    openModal () {
      this.$emit('openUploadModal')
    },
    open () {
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.default-page {
  padding: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
  .buttons-container {
    display: flex;
    margin-left: auto;
  }
  .close-modal-button {
    position: absolute;
    outline: none;
    width: 10px;
    fill: var(--secondary-text-color);
    right: 20px;
    cursor: pointer;
  }
  .create-button, .add-rss-button {
    .button {
      background: #2780eb;
      padding: 0px 10px;
      height: 36px;
      box-shadow: none;
      color: white;
      border: none;
    }
  }

  .add-rss-button {
    margin-left: auto;
  }

  .create-button {
    margin-left: 15px;
  }
}
</style>
